import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
import { AnchorToRouterService } from '../../services/anchor-to-router.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  imports: [CarouselModule],
  standalone: true,
})
export class BannerComponent implements AfterViewInit {
  @Input() banners!: Banner;
  @Input() headerType: HeaderMenuType = 'Hamburger';

  constructor(
    private el: ElementRef,
    private anchorToRouterService: AnchorToRouterService,
  ) {}

  ngAfterViewInit() {
    this.anchorToRouterService.convertAnchorsToRouterLinks(this.el);
  }

  anchorTag(e: Event, url: Image['Url']) {
    if (url === null || url == '') {
      e.preventDefault();
    }
  }

  imageUrl(url: Image['Url']): string {
    return url === null || url == '' ? '#' : url;
  }

  hasUrl(url: Image['Url']): boolean {
    return url !== null && url !== '';
  }

  getSrc(ImageUrl: string | null) {
    if (!ImageUrl) return '';
    const url = new URL(ImageUrl);
    url.searchParams.set('auto', 'true');
    // url.searchParams.set("format", "WebP");
    url.searchParams.set('cache', '8640000');
    url.searchParams.set('width', '2560');
    return url.toString();
  }
}
